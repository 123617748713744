import React, { useEffect, useRef, useState } from "react";
import "./services.scss";
import HeroBanner from "../../components/common/HeroBanner";
import Filter from "../../components/Filter";
import PlatinumCard from "../../components/PlatinumCard";
import Subscribe from "../../components/common/Subscribe";
import { getCreditCardPageData } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { subscribecard } from "../../components/helpers/subscribeData";
import { setPageListData } from "../../store/pageReducer";
import { pathNames } from "../../routes/WebRoutes";

export default function Services() {
  const { servicePageData } = useSelector((state) => state.pageReducer);
  const dispatch = useDispatch();
  const [paramsData, setParamsData] = useState({
    image: servicePageData?.banner_image,
    title: servicePageData?.banner_title,
    description: servicePageData?.banner_description,
  });
  const loading = useSelector((cardData) => cardData.cardSlice.loading);
  const cardsRef = useRef(null);

  const getCreditCardHeroBannerData = async () => {
    try {
      const response = await getCreditCardPageData();
      if (response.status == 200) {
        dispatch(
          setPageListData({ name: "servicePageData", data: response.data.data })
        );
        setParamsData({
          image: response.data.data?.banner_image,
          title: response.data.data?.banner_title,
          description: response.data.data?.banner_description,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    servicePageData.length === 0 && getCreditCardHeroBannerData();
  }, []);
  return (
    <div id="services">
      <HeroBanner
        image={servicePageData?.banner_image}
        title={servicePageData?.banner_title}
        description={servicePageData?.banner_description}
        link={servicePageData?.banner_link}
        linkData={paramsData}
        defaultLink={pathNames.OFFERPAGE}
        wrapperClass={"service-hero"}
        background={
          "linear-gradient(365.57deg, #000000 -4.88%, #014ea9 117.54%)"
        }
      />
      <Filter loading={loading} cardsRef={cardsRef}/>
      <PlatinumCard cardsRef={cardsRef}/>
      <Subscribe
        heading={subscribecard.heading}
        subscriptionType={"news_letter"}
      />
    </div>
  );
}
