import React, { useEffect } from "react";
import "./discover.scss";
import DiscoverSection from "../../components/DiscoverSection";
import CreditAnalysis from "../../components/CreditAnalysis";
import { getPageData } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { setPageListData } from "../../store/pageReducer";

export default function Discover() {
  const {discoverPageData} = useSelector((state)=> state.pageReducer)
  const dispatch = useDispatch()
  const getMyPageData = async () => {
    const response = await getPageData("discover");
    if (response.status == 200) {
      dispatch(
        setPageListData({ name: "discoverPageData", data: response.data.data })
      );
    }
  };
  useEffect(() => {
    discoverPageData.length === 0 && getMyPageData();
  }, []);
  return (
    <>
      <DiscoverSection />
      <CreditAnalysis />
    </>
  );
}
