import React, { useEffect, useRef, useState } from "react";
import "./customdropdown.scss";

const CustomDropdown = (props) => {
  const {
    data = ["credit card", "debit card"],
    selectedOption,
    sendIDBack,
    filterType,
    error,
    disabled,
    placeholder,
    dropDownClick
  } = props;

  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedVal, setSelectedVal] = useState("");
  const dropdownRef = useRef(null);
  const handleSelect = (data, id) => {
    setOpenDropdown(false);
    setSelectedVal(data);
    sendIDBack(id, filterType);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
  }, []);

  useEffect(()=>{
    setSelectedVal("")
  },[data])
  return (
    <div id="customdropdown" ref={dropdownRef}>
      <div
        className={`dropdownSelect ${disabled ? "disabled-dropdown" : ""}`}
        onClick={() => {dropDownClick(); !disabled && setOpenDropdown(!openDropdown)}}
      >
        {selectedVal || placeholder}

        <div className="icon-arrow"></div>

        {openDropdown && (
          <div className="dropdownMenu">
            <ul className="menuList">
              {data.length > 0 ? (
                data.map((val, i) => (
                  <li
                    className="menuItem"
                    onClick={() => handleSelect(val.name, val._id)}
                    key={i}
                    value={val._id}
                  >
                    {val.name}
                  </li>
                ))
              ) : (
                <li className="menuItem">No Data Found!</li>
              )}
            </ul>
          </div>
        )}
      </div>
      {error && <p className={`errormsg`}>{error}</p>}
    </div>
  );
};

export default CustomDropdown;
