import React, { useState } from "react";
import "./faqsection.scss";
import SectionWrapper from "../common/SectionWrapper";
import { AppImages } from "../../Images/images";
import { useSelector } from "react-redux";

export default function FAQSection() {
  const { cardComparePageData } = useSelector((state) => state.pageReducer);

  const [isActive, setActive] = useState("");
  const handleClick = (id) => {
    setActive(id);
    if (isActive === id) {
      setActive("");
    }
  };

  return (
    <SectionWrapper>
      <div className="FAQsection">
        <div className="question-container">
          <h1>{cardComparePageData?.FAQ}</h1>
        </div>
        <div>
          {cardComparePageData?.QA?.map((item, index) => (
            <div
              className={
                isActive === index
                  ? "question-wrapper borderWrapper"
                  : "question-wrapper"
              }
              key={item.question}
            >
              <div className="question" onClick={() => handleClick(index)}>
                <p>{item.question}</p>
                <button
                  className={isActive === index ? "button active" : "button "}
                  aria-label="Read FAQ"
                >
                  <img
                    loading={"lazy"}
                    className={
                      isActive === index
                        ? "faqarrowactive faqarrow"
                        : "faqarrow"
                    }
                    src={AppImages.FAQArrowicon}
                  />
                </button>
              </div>
              <div
                className={isActive === index ? "answer active" : "answer"}
              >
                {item.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </SectionWrapper>
  );
}
