import React, { useEffect, useState } from "react";
import "./exploreSection.scss";
import SectionWrapper from "../common/SectionWrapper";
import CustomButton from "../common/CustomButton";
import { AppImages } from "../../Images/images";
import CustomHeading from "../common/CustomHeading";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ExploreSection() {
  const { homePageData } = useSelector((state) => state.pageReducer);
  const [pageData, setPageData] = useState([]);
  const images = [AppImages.ExploreLoan, AppImages.ExploreAppartment];
  const navigate = useNavigate();
  const links = ["/carloan", "/apartment"];

  useEffect(() => {
    if (homePageData?.explore) {
      const addImage = homePageData?.explore.map((item, index) => {
        return {
          ...item,
          serviceImg: images[index],
          link: links[index],
        };
      });
      setPageData(addImage);
    }
  }, [homePageData]);
  return (
    <SectionWrapper>
      <div id="exploreSection">
        {pageData?.map((item, index) => {
          return (
            <div className="cardBox" key={index}>
              <div className="imgBox">
                <img loading="lazy" src={item?.serviceImg} />
              </div>
              <div className="exploreCard">
                <div className="dataBox">
                  <CustomHeading
                    text={item?.title}
                    color={"#FFFFFF"}
                    className={"title"}
                  />
                  <p className="detail">{item?.description}</p>
                  <CustomButton
                    text={"Explore"}
                    theme={"green"}
                    onClick={() => navigate(item.link)}
                  />
                </div>
                <img
                  loading={"lazy"}
                  src={AppImages.ServiceRightBg}
                  className="rightBg"
                />
                <img
                  loading={"lazy"}
                  src={AppImages.ServiceLeftBg}
                  className="leftBg"
                />
              </div>
            </div>
          );
        })}
      </div>
    </SectionWrapper>
  );
}
