import React from "react";

export default function TwitterLogo() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5464_123165)">
        <path
          d="M6.68883 6.19336L15.3559 17.7793H17.3185L8.65144 6.19336H6.68883Z"
          fill="currentColor"
        />
        <path
          d="M11.9866 0.131836C5.43934 0.131836 0.131868 5.43931 0.131868 11.9865C0.131868 18.5337 5.43934 23.8412 11.9866 23.8412C18.5338 23.8412 23.8412 18.5337 23.8412 11.9865C23.8412 5.43931 18.5338 0.131836 11.9866 0.131836ZM14.8928 18.7052L10.947 13.4294L6.06343 18.7052H4.80815L10.3862 12.6794L4.84318 5.26781H9.11535L12.7492 10.1267L17.2466 5.26781H18.5019L13.3101 10.8769L19.165 18.7052H14.8928Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5464_123165">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
