import React from "react";
import "./apartmentpage.scss";
import ApartmentBanner from "../../components/ApartmentBanner";
import Subscribe from "../../components/common/Subscribe";
import { getinformed } from "../../components/helpers/subscribeData";

export default function ApartmentPage() {
  return (
    <div id="apartment">
      <ApartmentBanner />
      <Subscribe heading2={getinformed.heading} subscriptionType={"apartments"}/>
    </div>
  );
}
