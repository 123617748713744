import React, { useEffect } from "react";
import "./offerspage.scss";
import FormBanner from "../../components/common/FormBanner";
import InfoTiles from "../../components/common/InfoTiles";
import OffersForm from "../../components/OffersForm";
import { useLocation, useNavigate } from "react-router-dom";

export default function OffersPage() {
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state === null) {
      navigate("/");
    }
  }, []);
  return (
    state && (
      <div id="offerspage">
        <FormBanner
          img={state?.image}
          title={state?.title}
          description={state?.description}
        />
        <InfoTiles />
        <OffersForm cardId={state?.id} />
      </div>
    )
  );
}
