import React from "react";
import "./custom-button.scss";

export default function CustomButton({ text, style, theme, onClick, type="button", className}) {
  return (
    <button
      style={style}
      type={type}
      onClick={onClick}
      className={`mybtn ${theme === "green" ? "greenTheme" : "blueTheme"} ${className}`}
    >
      {text}
    </button>
  );
}
