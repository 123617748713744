import React from "react";
import "./ourServices.scss";
import SectionWrapper from "../common/SectionWrapper";
import CustomHeading from "../common/CustomHeading";
import ServiceCard from "../ServiceCard";
import CustomButton from "../common/CustomButton";
import { AppImages } from "../../Images/images";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { pathNames } from "../../routes/WebRoutes";

export default function OurServices() {
  const { homePageData } = useSelector((state) => state.pageReducer);
  const navigate = useNavigate();
  const images = [
    AppImages.ServicePlanet,
    AppImages.ServiceSpaceman,
    AppImages.ServiceRocket,
  ];
  return (
    <div id="ourServices">
      <CustomHeading text={"Our Services"} />
      <div className="cards-box">
        <ServiceCard data={homePageData?.service} images={images} />
      </div>

      <CustomButton
        text={"Learn More"}
        theme={"green"}
        onClick={() => navigate(pathNames.SERVICES)}
      />
    </div>
  );
}
