import React from "react";
import "./carloan.scss";
import CarLoanBanner from "../../components/CarLoanBanner";
import Footer from "../../components/common/Footer";
import Subscribe from "../../components/common/Subscribe";
import { getinformed } from "../../components/helpers/subscribeData";

export default function CarLoan() {
  return (
    <div id="carloan">
      <CarLoanBanner />
      <Subscribe heading2={getinformed.heading} subscriptionType={"car_loan"}/>
    </div>
  );
}
