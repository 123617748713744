import React from "react";
import "./ourGuides.scss";
import CustomButton from "../common/CustomButton";
import { AppImages } from "../../Images/images";
import { useNavigate } from "react-router-dom";
import { pathNames } from "../../routes/WebRoutes";
import { useSelector } from "react-redux";

export default function OurGuides({ data }) {
  const { homePageData } = useSelector((state) => state.pageReducer);

  const navigate = useNavigate();
  return (
    <div id="ourguides">
      <div className="manage-card">
        <h1>{homePageData?.guide_title}</h1>

        <CustomButton
          text={"Read Our Guides"}
          theme={"green"}
          onClick={() => navigate(pathNames.COMPARECARDS)}
        />
      </div>
      <img
        src={AppImages.ServiceRightBg}
        className="rightBg"
      />
      <img src={AppImages.ServiceLeftBg} className="leftBg" />
    </div>
  );
}
