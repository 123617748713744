import { useEffect, useRef, useState } from "react";
import "./pagination.scss";
import { AppImages } from "../../../Images/images";

export default function Pagination({ allData, setPaginatedData, cardsRef }) {
  const pageCount = Math.ceil(allData.length / 3);

  const [currentPage, setCurrentPage] = useState(1);
  const prevCurrentPage = useRef(currentPage);

  function prevPage() {
    setCurrentPage?.((prevState) => (currentPage === 1 ? 1 : prevState - 1));
  }
  function nextPage() {
    setCurrentPage?.((prevState) =>
      currentPage === pageCount ? prevState : prevState + 1
    );
  }

  useEffect(() => {
    setPaginatedData(allData.slice(0, 3));
    setCurrentPage(1);
  }, [allData]);

  useEffect(() => {
    setPaginatedData(allData.slice(currentPage * 3 - 3, currentPage * 3));
    const prevPage = prevCurrentPage.current;
    prevCurrentPage.current = currentPage;
    if (currentPage !== prevPage) {
      setTimeout(() => {
        cardsRef?.current.scrollIntoView({ behavior: "smooth" });
      }, 20);
    }
  }, [currentPage]);

  return pageCount > 1 ? (
    <div className="pagination outer-padding">
      <button
        disabled={!(pageCount > 1) || currentPage === 1}
        type="button"
        onClick={prevPage}
        className="page-change-btn"
      >
        <img loading="lazy" src={AppImages.PrePage} />
      </button>
      {[...new Array(pageCount).fill(0)]
        .map((_, i) => i + 1)
        ?.map((page) => {
          if (
            page === 1 ||
            currentPage === page ||
            currentPage + 1 === page ||
            page === currentPage - 1 ||
            page === pageCount - 1 ||
            page === pageCount
          ) {
            return (
              <div key={page}>
                <button
                  type="button"
                  onClick={() => {
                    setCurrentPage?.(page);
                  }}
                  className={` ${
                    currentPage === page ? "current-page" : "other-page"
                  }  page-nomber-btn`}
                >
                  {page}
                </button>
                {page === pageCount - 2 && (
                  <span key={"dot" + page} className="dots">
                    ...
                  </span>
                )}
              </div>
            );
          }

          if (page === pageCount - 2)
            return (
              <span key={"dot" + page} className="dots">
                ....
              </span>
            );

          return null;
        })}
      <button
        disabled={!(pageCount > 1) || currentPage === pageCount}
        type="button"
        onClick={nextPage}
        className="page-change-btn"
      >
        <img loading="lazy" src={AppImages.NextPage} />
      </button>
    </div>
  ) : (
    ""
  );
}
