import React, { useEffect, useState } from "react";
import "./discovercardswiper.scss";
import CustomButton from "../common/CustomButton";
import { useNavigate } from "react-router-dom";
import { AppImages } from "../../Images/images";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

export default function DiscoverCardSwiper({
  data,
  buttonVisible = true,
  images,
}) {
  const [cardsData, setCardsData] = useState([]);
  const navigate = useNavigate();
  const links = ["/services", "/apartment", "/carloan"];
  const handleOnClick = (link) => {
    window.location.href = link;
  };
  useEffect(() => {
    if (data) {
      if (data.images) {
        const addImage = Object.values(data).map((item, index) => {
          return {
            ...item,
            serviceImg: images[index],
            link: item.button_link ? item.button_link : links[index],
          };
        });
        setCardsData(addImage);
      } else {
        setCardsData(data);
      }
    }
  }, [data]);

  return (
    <div id="cardSwiper">
      <Swiper
        slidesPerView={3.3}
        spaceBetween={30}
        grabCursor={true}
        navigation={true}
        className="mySwiper"
        autoHeight={false}
        pagination={{
          clickable: true,
          dynamicBullets: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
          }
        }}
        modules={[Pagination]}
        breakpoints={{
          0: {
            slidesPerView: 1.1,
            spaceBetween: 15,
          },
          425: {
            slidesPerView: 1.27,
            spaceBetween: 15,
          },
          640: {
            slidesPerView: 2.1,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 3.1,
            spaceBetween: 15,
          },
          1750: {
            slidesPerView: 4.1,
            spaceBetween: 15,
          },
        }}
      >
        {cardsData?.map((card, index) => {
          return (
            <SwiperSlide id=" key={index}">
              <div className={"card"}>
                <img
                  loading="lazy"
                  src={card?.serviceImg || AppImages.CreditAnalysis}
                />
                <div className="data-box">
                  <h2 className="title">{card?.title}</h2>
                  <p className="detail">{card?.description}</p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
