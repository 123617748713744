import React from "react";
import "./infotiles.scss";
import SectionWrapper from "../SectionWrapper";
import { AppImages } from "../../../Images/images";

export default function InfoTiles() {
  const infoTilesData = [
    {
      id: 1,
      image: AppImages.CashbackRewards,
      title: "Cash Back Rewards",
      info: "Earn cash back rewards on eligible purchases",
    },
    {
      id: 2,
      image: AppImages.FreeCreditScore,
      title: "Free Credit Score",
      info: "View your credit score and credit report summary online",
    },
    {
      id: 3,
      image: AppImages.ZeroFraudLiability,
      title: "Zero Fraud Liability",
      info: "Earn cash back rewards on eligible purchases",
    },
    {
      id: 4,
      image: AppImages.CreditlineIncreases,
      title: "Credit Line Increases",
      info: "Earn cash back rewards on eligible purchases",
    },
  ];

  return (
    <SectionWrapper>
      <div id="infotiles">
        <div className="infotiles-container">
          {infoTilesData.map((item, index) => {
            return (
              <div key={index} className="infotiles-items">
                <img loading="lazy" src={item.image} />
                <h3>{item.title}</h3>
                <p>{item.info}</p>
              </div>
            );
          })}
        </div>
      </div>
    </SectionWrapper>
  );
}
