import { combineReducers } from '@reduxjs/toolkit';
import pageReducer from './pageReducer';
import categoryReducer from './categoryReducer';
import cardSlice from './cardSlice';
const rootReducer = combineReducers({
    pageReducer,
    categoryReducer,
    cardSlice,
});

export default rootReducer;