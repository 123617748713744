import React from "react";

export default function FacebookLogo() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5464_123169)">
        <path
          d="M11.9866 0.0722656C5.43934 0.0722656 0.131866 5.40688 0.131866 11.9873C0.131866 17.9952 4.55683 22.9621 10.3092 23.7814V15.6169H7.50627V12.3369H10.3092V9.91798C10.3092 7.11079 12.006 5.58232 14.4845 5.58232C15.6715 5.58232 16.6921 5.67163 16.9892 5.71167V8.64531L15.2703 8.6461C13.9226 8.6461 13.6615 9.29337 13.6615 10.2428V12.3369H16.876L16.4574 15.6169H13.6618V23.7816C19.4152 22.9634 23.8412 17.996 23.8412 11.9873C23.8412 5.40688 18.5338 0.0722656 11.9866 0.0722656Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5464_123169">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
