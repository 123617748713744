import React from "react";
import "./heroBanner.scss";
import SectionWrapper from "../SectionWrapper";
import CustomButton from "../CustomButton";
import { useNavigate } from "react-router-dom";
export default function HeroBanner({
  image,
  title,
  description,
  defaultLink = "/",
  link,
  buttonVisible = true,
  background,
  linkData,
  wrapperClass,
  isWrapper = true,
  buttonText="Explore"
}) {
  const navigate = useNavigate();
  const handleLink = (link) => {
    link
      ? link.startsWith("/")
        ? navigate(link, { state: linkData })
        : (window.location.href = link)
      : navigate(defaultLink, { state: linkData });
  };
  return isWrapper ? (
    <SectionWrapper>
      <div
        id="heroBanner"
        style={background ? { background: background } : {}}
        className={wrapperClass}
      >
        <div className={"imgBox"}>
          <img loading="eager" src={image} />
        </div>
        <div className="dataBox">
          <h1 className="title">{title}</h1>
          <p className="detail">{description}</p>
          {buttonVisible && (
            <CustomButton
              text={buttonText}
              theme={"green"}
              onClick={() => handleLink(link)}
            />
          )}
        </div>
      </div>
    </SectionWrapper>
  ) : (
    <div
      id="heroBanner"
      style={background ? { background: background } : {}}
      className={wrapperClass}
    >
      <div className={"imgBox"}>
        <img loading="eager" src={image} />
      </div>
      <div className="dataBox">
        <h1 className="title">{title}</h1>
        <p className="detail">{description}</p>
        {buttonVisible && (
          <CustomButton
            text="Explore"
            theme={"green"}
            onClick={() => handleLink(link)}
          />
        )}
      </div>
    </div>
  );
}
