import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    homePageData: [],
    modelOpened: false,
    servicePageData: [],
    aboutUsPageData: [],
    discoverPageData: [],
    cardComparePageData: [],
}

export const pageListDataSlice = createSlice({
    name: "page slice",
    initialState,
    reducers: {
        setPageListData: (state, action) =>{
            state[action.payload.name] = action.payload.data
        }
    }
})

export const {setPageListData} = pageListDataSlice.actions;
export default pageListDataSlice.reducer;