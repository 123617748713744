import React from "react";
import "./section-wrapper.scss";

function SectionWrapper({ background = "", children, pd, className, style, ...props }) {
  return (
    <div style={{ background: background, ...style, ...props }} className={className}>
      <div
        className="sectionWrapper"
      >
        {children}
      </div>
    </div>
  );
}

export default SectionWrapper;
