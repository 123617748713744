export function isEmailValid(email) {
  const pattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  email = email.trim();

  if (!pattern.test(email)) {
    return "Please enter a valid email address.";
  }

  return "";
}

export const isEmpty = (value)=>{
  if(value.trim().length <= 0){
    return "This field can not be empty!"
  }
  else{
    return ""
  }
}
