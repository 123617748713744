import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import AppRoutes from "./routes";
import store from "./store";
import "./styles/globals.scss";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <AppRoutes />
        <Toaster />
      </Provider>
    </BrowserRouter>
  );
}

export default App;
