import Logo from "./logo4.svg";
import BannerBg from "./herobannerbg.jpg";
import HeroCards from "./heroCards.png";
import ExclusiveCard from "./exclusiveCard.png";
import ServiceCreditCard from "./serviceCreditCard.svg";
import ServiceAppartment from "./serviceAppartment.svg";
import ServiceCarLoan from "./serviceCarLoan.svg";
import ServiceRightBg from "./ourServiceRightBg.svg";
import ServiceLeftBg from "./ourServiceLeftBg.svg";
import ProcessApplication from "./processApplication.svg";
import ProcessDocumentation from "./processDocumentation.svg";
import ProcessCreditAssessment from "./processCreditAssessment.svg";
import ProcessVerification from "./processVerification.svg";
import CreditCard from "./creditCard.svg";
import ExploreLoan from "./exploreLoan.svg";
import ExploreAppartment from "./exploreAppartment.svg";
import NavIcon from "./NavIcon";
import PlatinumCard from "./platinumcard.png";
import CashbackRewards from "./cashbackrewards.svg";
import CreditlineIncreases from "./creditlineincreases.svg";
import FreeCreditScore from "./freecreditscore.svg";
import ZeroFraudLiability from "./zerofraudliability.svg";
import FAQArrowicon from "./faqarrow-icon.svg";
import StarBg from "./star.svg";
import EarthBg from "./earth.png";
import aboutSectionImg from "./aboutSection.svg";
import SmallStarImg from "./smallStar.svg";
import ServicePlanet from "./planet.svg";
import ServiceSpaceman from "./spaceman.svg";
import ServiceRocket from "./rocket.svg";
import OurServicebgBanner from "./ourservicebgbanner.svg";
import FooterAstronaut from "./astronaut.png";
import FacbookLogo from "./facebooklogo.svg";
import TwitterLogo from "./twitterlogo.svg";
import InstagramLogo from "./instagramlogo.svg";
import PrePage from './prevPage.svg'
import NextPage from './nextPage.svg'
import AboutBanner from "./aboutbanner.png";
import OurMission from "./ourmission.png";
import OurSuccess from "./oursuccess.png";
import DiscoverFreedom from "./financialfreedom.png";
import DiscoverComingChallenges from "./overcomingchallenges.png";
import DiscoverFirststep from "./takingfirststep.png";
import DiscoverCredit from "./understandingcredit.png";
import CreditAnalysis from "./creditanalysis.svg";
import CloseCross from './cross.svg'

export const AppImages = {
  Logo,
  BannerBg,
  HeroCards,
  ExclusiveCard,
  ServiceCreditCard,
  ServiceAppartment,
  ServiceCarLoan,
  ServiceRightBg,
  ServiceLeftBg,
  ProcessApplication,
  ProcessDocumentation,
  ProcessCreditAssessment,
  ProcessVerification,
  ExploreLoan,
  ExploreAppartment,
  CreditCard,
  PlatinumCard,
  CashbackRewards,
  CreditlineIncreases,
  FreeCreditScore,
  ZeroFraudLiability,
  FAQArrowicon,
  ServicePlanet,
  ServiceSpaceman,
  ServiceRocket,
  OurServicebgBanner,
  FooterAstronaut,
  FacbookLogo,
  TwitterLogo,
  InstagramLogo,
  NavIcon,
  StarBg,
  EarthBg,
  aboutSectionImg,
  SmallStarImg,
  PrePage,
  NextPage,
  AboutBanner,
  OurMission,
  OurSuccess,
  DiscoverFreedom,
  DiscoverComingChallenges,
  DiscoverFirststep,
  DiscoverCredit,
  CreditAnalysis,
  CloseCross,
};
