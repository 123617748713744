import React from "react";
import "./customInput.scss";

export default function CustomInput({type="text", placeholder, error, value, handleChange}) {
  return (
    <div className="input-container" id="input-container">
      <input
        type={type}
        value={value}
        className="input"
        placeholder={placeholder}
        onChange={handleChange}
      />
       {error && <p className={`errormsg`}>{error}</p>}
    </div>
  );
}
