import React from "react";
import "./ourmission.scss";
import SectionWrapper from "../common/SectionWrapper";
import { useSelector } from "react-redux";


export default function OurMission() {
  const { aboutUsPageData } = useSelector((state)=> state.pageReducer)
  return (
    <>
      <SectionWrapper>
        <div id="ourmission">
          {aboutUsPageData?.mission?.map((item, index) => {
            return (
              <div className="ourmission-container" key={index}>
                <div className="mission-image">
                  <img loading="lazy" src={item.image} />
                </div>
                <div className="mission-description">
                  <h1>{item.title}</h1>
                  <p>{item.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </SectionWrapper>
    </>
  );
}
