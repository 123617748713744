import axiosInstance from "../utils/axiosInstance";
import { API_ROUTES } from "./apiRoutes";

export const getPageList = async () => {
  try {
    const response = await axiosInstance.get(API_ROUTES.GET_PAGE_LIST);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getPageData = async (pageName) => {
  try {
    const response = await axiosInstance.get(API_ROUTES.GET_PAGE_DATA + pageName);
    return response;
  } catch (error) {
    return error.response?.data;
  }
};


export const getCreditCardPageData= async ()=>{
  try {
    const response = await axiosInstance.get(API_ROUTES.GET_CREDIT_CARD_PAGE_DATA);
    return response;
  } catch (error) {
    return error.response.data;
  }
}

export const getCreditCardCategories= async ()=>{
  try {
    const response=await axiosInstance.get(API_ROUTES.GET_CREDIT_CARD_CATEGORY)
    return response
  } catch (error) {
    return error.response.data;
  }
}

export const getCreditCardSubCategories= async ()=>{
  try {
    const response= await axiosInstance.get(API_ROUTES.GET_CREDIT_CARD_SUBCATEGORY)
    return response
  } catch (error) {
    return error.response.data
  }
}

export const getAllCreditCard= async (category,subCategory)=>{
  try {
    const response= await axiosInstance.get(API_ROUTES.GET_ALL_CREDIT_CARD+`?categoryId=${category?category:""}&subCategoryId=${subCategory?subCategory:""}`)
    return response
  } catch (error) {
    return error.response.data
  }
}

export const SendForm= async (data)=>{
  try {
    const response= await axiosInstance.post(API_ROUTES.SUBMIT_FORM,data)
    return response
  } catch (error) {
    return error.response.data
  }
}

export const addSubscription= async (data)=>{
  try {
    const response= await axiosInstance.post(API_ROUTES.ADD_SUBSCRIPTION,data)
    return response
  } catch (error) {
    return error.response.data
  }
}

export const getFooter = async ()=>{
  try {
    const response= await axiosInstance.get(API_ROUTES.GET_FOOTER)
    return response
  } catch (error) {
    return error.response.data
  }
}

export const getCreditCardBenefit = async ()=>{
  try {
    const response= await axiosInstance.get(API_ROUTES.GET_CREDITCARD_BENEFITS)
    return response
  } catch (error) {
    return error.response.data
  }
}

export const getWelcomeModel = async ()=>{
  try {
    const response= await axiosInstance.get(API_ROUTES.GET_MODEL)
    return response
  } catch (error) {
    return error.response.data
  }
}

export const getCardCompare = async ()=>{
  try {
    const response= await axiosInstance.get(API_ROUTES.GET_CARD_COMPARE)
    return response
  } catch (error) {
    return error.response.data
  }
}

export const getContactUs = async ()=>{
  try {
    const response= await axiosInstance.get(API_ROUTES.GET_CONTACT_US)
    return response
  } catch (error) {
    return error.response.data
  }
}

export const sendContactUs = async (data)=>{
  try {
    const response= await axiosInstance.post(API_ROUTES.POST_CONTACT_US, data)
    return response
  } catch (error) {
    return error.response.data
  }
}

