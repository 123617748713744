import "./home.scss";
import React, { useEffect } from "react";
import HeroBanner from "../../components/common/HeroBanner";
import ExclusiveCards from "../../components/ExclusiveCards";
import OurServices from "../../components/OurService";
import OurGuides from "../../components/OurGuides";
import ExploreSection from "../../components/ExploreSection";
import { useDispatch, useSelector } from "react-redux";
import { setPageListData } from "../../store/pageReducer";
import { getPageData } from "../../services";
import { AppImages } from "../../Images/images";
import WelcomeModel from "../../components/WelomeModel";

export default function Home() {
  const { homePageData } = useSelector((state) => state.pageReducer);

  const dispatch = useDispatch();

  const getMyPageData = async () => {
    const response = await getPageData("Home_page");
    if (response.status == 200) {
      dispatch(
        setPageListData({ name: "homePageData", data: response.data.data })
      );
    }
  };
  useEffect(() => {
    homePageData.length === 0 && getMyPageData();
  }, []);
  return (
    <div id="home">
      <HeroBanner
        image={homePageData?.banner_image}
        title={homePageData?.banner_title}
        description={homePageData?.banner_description}
        link={homePageData?.hero_button_link}
        buttonText={"Get Started"}
        background={`url(${AppImages.BannerBg})`}
      />
      <ExclusiveCards />
      <OurServices />
      <ExploreSection />
      <OurGuides />
      <WelcomeModel />
    </div>

  );
}
