import React, { useEffect, useState } from "react";
import "./filter.scss";
import SectionWrapper from "../common/SectionWrapper";
import CustomHeading from "../common/CustomHeading";
import CustomDropdown from "../common/CustomDropdown";
import CustomButton from "../common/CustomButton";
import {
  getCreditCardCategories,
  getCreditCardSubCategories,
} from "../../services";
import { useDispatch } from "react-redux";
import { cardApi } from "../../store/cardSlice";

export default function Filter({ loading, cardsRef }) {
  const [mainCategory, setMainCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const initialErrors = {
    categoryErr: "",
    subCategoryErr: ""
  }
  const [errors, setErrors] = useState(initialErrors)

  const dispatch = useDispatch();

  const getCategoryData = async () => {
    try {
      const response = await getCreditCardCategories();
      if (response.status == 200) {
        setMainCategory(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const getSubCategoryData = async () => {
    try {
      const response = await getCreditCardSubCategories();

      if (response.status == 200) {
        setSubCategory(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSubCategoryData();
    getCategoryData();
  }, []);

  useEffect(() => {
    for (const item of mainCategory) {
      if (item._id === categoryId) {
        setSubCategory(item.subcategories);
      }
    }
  }, [categoryId]);

  const filterCreditCards = (id, type) => {
    if (type == "category") {
      setCategoryId(id);
      setSubCategoryId("");
      setErrors(initialErrors)
    }
    if (type == "sub category") {
      setSubCategoryId(id);
      setErrors({...errors, subCategoryErr: ""})
    }
  };
  const handleFilterClick = async () => {
    if(!categoryId || !subCategoryId){
      const errors = {
        categoryErr: !categoryId ? "Please select category" : "",
        subCategoryErr: !subCategoryId ? "Please select sub category" : ""
      }
      setErrors(errors)
      return
    }
    setErrors(initialErrors)
    const params = {
      category: categoryId,
      subCategory: subCategoryId,
    };
    await dispatch(cardApi(params));
  };
  useEffect(() => {}, []);
  return (
    <SectionWrapper>
      <div id="filter" ref={cardsRef}>
        <CustomHeading text={"Find Your Perfect Credit Card"} />
        <div className="dropdown">
          <CustomDropdown
            data={mainCategory}
            sendIDBack={filterCreditCards}
            filterType="category"
            dropDownClick={()=> console.log("called")}
            error={errors.categoryErr}
            placeholder="Select Category"
          />
          <CustomDropdown
            data={subCategory}
            sendIDBack={filterCreditCards}
            filterType="sub category"
            dropDownClick={()=> categoryId ? "" : setErrors({...errors, subCategoryErr: "Please select category first!"}) }
            error={errors.subCategoryErr}
            disabled={categoryId ? false : true}
            placeholder="Select Sub Category"
          />
          <CustomButton
            text={loading ? "Loading..." : "Filter"}
            theme={"green"}
            onClick={handleFilterClick}
          />
        </div>
      </div>
    </SectionWrapper>
  );
}
