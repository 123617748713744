import React from "react";
import "./apartmentbanner.scss";
import SectionWrapper from "../common/SectionWrapper";
import { AppImages } from "../../Images/images";

export default function ApartmentBanner() {
  return (
    <SectionWrapper>
      <div id="apartmentbanner">
        <div className="apartmentbanner-container">
          <div className="apartmentbanner-info">
            <h1>COMING SOON!</h1>
            <p>
              Forem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus.
            </p>
          </div>
          <div className="apartmentbanner-image">
            <img
              loading={"lazy"}
              className="apartment-img"
              src={AppImages.ExploreAppartment}
            />
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
}
