import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  category: "",
  subCategory:"",
};

export const categorySlice = createSlice({
    name:"category slice",
    initialState:initialState,
    reducers:{
            setCategoryStoreId:(state,action)=>{
                state.category=action.payload
            },
            setSubCategoryStoreId:(state,action)=>{
                state.subCategory=action.payload
            }
    },
})
export const {setCategoryStoreId,setSubCategoryStoreId}=categorySlice.actions
export default categorySlice.reducer;