import React, { useState } from "react";
import "./subscribe.scss";
import { AppImages } from "../../../Images/images";
import CustomButton from "../CustomButton";
import CustomInput from "../CustomInput";
import { isEmailValid } from "../../../utils/validation";
import { addSubscription } from "../../../services";
import toast from "react-hot-toast";

export default function Subscribe({ heading, heading2, subscriptionType }) {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const handleEmail = (value) => {
    setEmail(value.trim());
    value
      ? setEmailError(isEmailValid(value))
      : setEmailError("Please enter an email");
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    const emailErr = email ? isEmailValid(email) : "Please enter an email";
    if (emailErr) {
      setEmailError(emailErr);
      return;
    }
    const response = await addSubscription({
      email: email,
      subscriptionType: subscriptionType,
    });
    if (response.status === 201) {
      toast.success("Thank you for subscribe");
      setEmail("");
      setEmailError("");
    } else {
      toast.error("Error");
    }
  };
  return (
    <div id="subscribe">
      {heading && (
        <div className="subscribe-card">
          <h1>{heading}</h1>
        </div>
      )}
      {heading2 && (
        <div className="subscribe-card2">
          <h1>{heading2}</h1>
        </div>
      )}
      <div className="subscribe-input">
        <CustomInput
          placeholder={"Enter your email address"}
          value={email}
          handleChange={(e) => handleEmail(e.target.value)}
          error={emailError}
        />
        <CustomButton
          text={"Subscribe"}
          theme={"green"}
          onClick={handleSubscribe}
        />
      </div>
      <img src={AppImages.ServiceRightBg} className="rightBg" />
      <img src={AppImages.ServiceLeftBg} className="leftBg" />
    </div>
  );
}
