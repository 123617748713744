import React from 'react'
import './custom-heading.scss'

export default function CustomHeading({firstLetter, text, style, color, className, textClassName, firstLetterClassName}) {
  return (
    <div id='customHeading' className={className}>
    <h1 className={`title ${textClassName}`} style={{color: color, ...style}}><span className={firstLetterClassName}>{firstLetter}</span>{text}</h1>
    </div>
  )
}
