import React, { useEffect, useState } from "react";
import "./serviceCard.scss";
import CustomButton from "../common/CustomButton";
import { useNavigate } from "react-router-dom";

export default function ServiceCard({ data, buttonVisible = true, images }) {
  const [cardsData, setCardsData] = useState([]);
  const navigate = useNavigate();
  const links = ["/services", "/apartment", "/carloan"];
  const handleOnClick = (link) => {
    window.location.href = link;
  };
  useEffect(() => {
    if (data) {
      const addImage = Object.values(data).map((item, index) => {
        return {
          ...item,
          serviceImg: images[index],
          link: item.button_link ? item.button_link : links[index],
        };
      });
      setCardsData(addImage);
    }
  }, [data]);

  return (
    <div id="serviceCard">
      {cardsData?.map((card, index) => {
        return (
          <div
            className={`card ${buttonVisible ? "" : "hidebutton"}`}
            key={index}
          >
            <img loading="lazy" src={card.serviceImg} />
            <div className="data-box">
              <h2 className="title">{card?.title}</h2>
              <p className="detail">{card?.description}</p>
            </div>
            {buttonVisible && (
              <div style={{ margin: "auto" }}>
                <CustomButton
                  text={"Explore"}
                  theme={"green"}
                  onClick={() => {
                    card?.button_link?.startsWith("/")
                      ? navigate(card.button_link)
                      : (window.location.href = card.button_link);
                  }}

                  // onClick={() => handleOnClick(card.button_link)}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
