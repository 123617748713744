export const API_ROUTES = {
  GET_PAGE_LIST: "/admin/page/pagelist/",
  GET_PAGE_DATA: "/admin/",
  GET_CREDIT_CARD_PAGE_DATA: "admin/Credit_card",
  GET_CREDIT_CARD_CATEGORY: "admin/card/categories",
  GET_CREDIT_CARD_SUBCATEGORY: "admin/card/subcategories",
  GET_ALL_CREDIT_CARD: `admin/card/creditCards`,
  GET_CREDITCARD_BENEFITS: "admin/benefit/getbenefits",
  SUBMIT_FORM: "admin/adduser",
  ADD_SUBSCRIPTION: "admin/addSubscription",
  GET_MODEL: "/admin/popup",
  GET_CARD_COMPARE: "admin/tips&card_compare",
  GET_FOOTER: "/admin/footer/getFooters",
  GET_CONTACT_US: "admin/ContactUs",
  POST_CONTACT_US: "admin/addContact",
};
