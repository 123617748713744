import React, { useEffect, useRef, useState } from "react";
import "./navbar.scss";
import SectionWrapper from "../common/SectionWrapper";
import { AppImages } from "../../Images/images";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../Images/Logo";

export default function Navbar() {
  const refModel = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const headerData = [
    { title: "Home", path: "/" },
    { title: "About", path: "/about" },
    { title: "Services", path: "/services" },
    { title: "Discover", path: "/discover" },
    { title: "Contact", path: "/contactus" },

    // { title: "Credit Card", path: "/creditcard" },
    // { title: "Car Loan", path: "/carloan" },
    // { title: "Apartments", path: "/apartment" },
  ];
  const location = useLocation();
  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (refModel.current && !refModel.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refModel]);

  return (
    <SectionWrapper background="linear-gradient(to bottom,  #000000 -9.18%, #014EA9 158.14%)">
      <nav id="navbar">
        <div className="logo-container">
          <img loading="lazy" src={AppImages.Logo} className="logo-img" />
        </div>
        <div className="menu-container" ref={refModel}>
          <div onClick={handleShowMenu}>
            <AppImages.NavIcon className={"mobile-nav"} />
          </div>
          <ul className={`menu ${!showMenu ? "hideMenu" : ""}`}>
            {headerData.map((item, index) => {
              return (
                <li key={index}>
                  <Link
                    to={item.path}
                    className={
                      location.pathname === item.path ||
                      (location.pathname === "/offerpage" &&
                        item.path === "/services")
                        ? "active-item"
                        : undefined
                    }
                    onClick={() => setShowMenu(false)}
                  >
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    </SectionWrapper>
  );
}
