import React from "react";
import "./formbanner.scss";
import SectionWrapper from "../SectionWrapper";

export default function FormBanner({ img, title, title2, description }) {
  return (
    <SectionWrapper>
      <div id="formbanner">
        <div className="formbanner-container">
          <div className="formbanner-img">
            <img loading="lazy" src={img} />
          </div>

          <div className="formbanner-data">
            {title && (
              <div className="formbanner-title">
                <h1>{title}</h1>
              </div>
            )}
            {title2 && (
              <div className="formbanner-title2">
                <h1>{title2}</h1>
              </div>
            )}
            <div className="formbanner-description">
              {description?.replace(/\\n/g, "\n")
                .split("\n")
                .map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
}
