import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllCreditCard } from "../services";

export const cardApi = createAsyncThunk(
  "cardApi",
  async ({ category, subCategory }, { rejectWithValue }) => {
    try {
      const response = await getAllCreditCard(
        category ? category : "",
        subCategory ? subCategory : ""
      );
      if (!response || !response.data) {
        return rejectWithValue("Invalid response structure");
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const cardSlice = createSlice({
  name: "cardSlice",
  initialState: {
    loading: false,
    error: null,
    cardDetail: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(cardApi.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(cardApi.fulfilled, (state, action) => {
      state.loading = false;
      state.cardDetail = action.payload.data.data;
    });
    builder.addCase(cardApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || "Something went wrong";
    });
  },
});

export default cardSlice.reducer;
