import React from "react";
import "./choosecreditcard.scss";
import SectionWrapper from "../common/SectionWrapper";
import { useSelector } from "react-redux";

export default function ChooseCreditcard() {
  const { cardComparePageData } = useSelector((state) => state.pageReducer);
  return (
    <SectionWrapper>
      <div id="choosecreditcard">
        <div className="choosecard-info">
          <h1>{cardComparePageData?.tips_title}</h1>
          <p>{cardComparePageData?.tips_discription}</p>
        </div>
      </div>
    </SectionWrapper>
  );
}
