import React, { useEffect, useState } from "react";
import "./contactsection.scss";
import SectionWrapper from "../common/SectionWrapper";
import OfferInput from "../common/OfferInput";
import CustomButton from "../common/CustomButton";
import { getContactUs, sendContactUs } from "../../services";
import { isEmailValid, isEmpty } from "../../utils/validation";
import toast from "react-hot-toast";

export default function ContactSection() {
  const [contactLink, setContactLink] = useState("");
  const initialData = {
    name: "",
    email: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialData);
  const [errors, setErrors] = useState(initialData);

  const getPageData = async () => {
    const response = await getContactUs();
    if (response.status === 200) {
      const srcValue = response.data.data.contactus_link.match(/src="([^"]*)"/);
      srcValue ? setContactLink(srcValue[1]) : setContactLink(response.data.data.contactus_link)
    } else {
      console.log("Error : ", response);
    }
  };

  const validate = (name, value) => {
    if (value.length > 0) {
      if (name === "email") {
        setErrors({ ...errors, email: isEmailValid(value) });
        return;
      }
    }
    setErrors({ ...errors, [name]: isEmpty(value) });
  };

  const handleChange = (name, value) => {
    let trimValue = value.startsWith(" ") ? value.replace(" ", "") : value;
    setFormData({ ...formData, [name]: trimValue });
    validate(name, trimValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    for (const item in formData) {
      errors[item] = isEmpty(formData[item]);
    }
    setErrors(errors);
    if (Object.values(errors).every((val) => val === "")) {
      const response = await sendContactUs(formData);
      if (response.status === 200) {
        toast.success("Form submitted successfully!");
        setFormData(initialData);
        setErrors(initialData);
      } else {
        toast.error("Error while submitting form!");
      }
    }
  };

  useEffect(() => {
    getPageData();
  }, []);

  return (
    <SectionWrapper>
      <div id="contactsection">
        <div className="contactsection-title">
          <h1>Contact Us</h1>
        </div>
        <div className="contactsection-container">
          <div className="contactsection-input">
            <OfferInput
              className="contact-input"
              type="text"
              placeholder="Name"
              name="name"
              label=" Name"
              value={formData.name}
              handleChange={(e) => handleChange("name", e.target.value)}
              error={errors.name}
            />
            <OfferInput
              type="text"
              placeholder="you@company.com"
              name="email"
              label="Email Address"
              value={formData.email}
              handleChange={(e) => handleChange("email", e.target.value)}
              error={errors.email}
            />
            <div className="textarea-container">
              <label>Message <span className="requiredStar">*</span></label>
              <textarea
                className="text-area"
                label="Message"
                name="message"
                value={formData.message}
                onChange={(e) => handleChange("message", e.target.value)}
              />
              {errors.message && <p className={`errormsg`}>{errors.message}</p>}
            </div>
            <CustomButton
              text={"Send Message"}
              theme={"green"}
              onClick={handleSubmit}
            />
          </div>
          <div className="contactsection-map">
            <iframe
              src={contactLink}
              width="100%"
              height={450}
              style={{ borderRadius: 16 }}
              allowFullScreen=""
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
}
