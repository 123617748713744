import React, { useEffect } from "react";
import "./about.scss";
import OurMission from "../../components/OurMission";
import { useDispatch, useSelector } from "react-redux";
import { getPageData } from "../../services";
import { setPageListData } from "../../store/pageReducer";
import HeroBanner from "../../components/common/HeroBanner";
import { AppImages } from "../../Images/images";

export default function About() {
  const { aboutUsPageData } = useSelector((state)=> state.pageReducer)
  const dispatch = useDispatch();

  const getMyPageData = async () => {
    const response = await getPageData("about_us");
    if (response.status == 200) {
      dispatch(setPageListData({name: "aboutUsPageData", data: response.data.data}));
    }
  };
  useEffect(() => {
    aboutUsPageData.length === 0 && getMyPageData()
  }, []);
  return (
    <div id="aboutus">
      <HeroBanner
        wrapperClass={"hero-section"}
        image={aboutUsPageData?.aboutus_image}
        title={"About Us"}
        description={aboutUsPageData?.aboutus_description}
        buttonVisible={false}
        isWrapper={false}
        background={`url(${AppImages.SmallStarImg}),linear-gradient(363.57deg, #000000 -4.88%, #014ea9 117.54%)`}
      />
      <OurMission />
    </div>
  );
}
