import React from "react";
import "./offerinput.scss";

export default function OfferInput({
  title,
  maxChar,
  minChar,
  value,
  accept,
  handleChange,
  error,
  id,
  disabled,
  label,
  maxValue,
  minValue,
  autoComplete = "off",
  isRequired = true,
  dataFilter,
  ...props
}) {
  return (
    <div className="input-container" id="offerinput-container">
      {label && (
        <label className="input-label">
          <>
            {label} {isRequired && <span className="requiredStar">*</span>}
          </>
        </label>
      )}
      <input
        value={value}
        title={title}
        maxLength={maxChar}
        minLength={minChar}
        max={maxValue}
        min={minValue}
        className={` input-field ${props.className}`}
        type={props.type}
        accept={accept}
        onChange={handleChange}
        disabled={disabled}
        name={props.name}
        id={id}
        placeholder={props.placeholder}
        autoComplete={autoComplete}
      />
      {error && <p className={`errormsg`}>{error}</p>}
    </div>
  );
}
