import React from "react";
import "./videoPlayer.scss";

const VideoPlayer = ({ source="" }) => {
  const isYouTube = source.includes("youtube.com");

  if (isYouTube) {
    const videoId = source.split("v=")[1];
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return (
      <div id="video-player">
          <div className="video-container">
            <iframe
              title="YouTube Video"
              src={embedUrl}
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              className="video-player"
            ></iframe>
          </div>
      </div>
    );
  } else {
    return (
      <div id="video-player">
        <div className="video-container">
          <video controls className="video-player">
            <source src={source} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    );
  }
};

export default VideoPlayer;
