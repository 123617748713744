import React from "react";
import "./exclusive-cards.scss";
import CustomHeading from "../common/CustomHeading";
import CustomButton from "../common/CustomButton";
import { AppImages } from "../../Images/images";
import AboutSection from "../AboutSection";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ExclusiveCards() {
  const { homePageData } = useSelector((state) => state.pageReducer);
  const navigate = useNavigate();
  return (
    <>
      <div id="exclusiveCards">
        <CustomHeading
          text={homePageData?.card_title?.slice(1)}
          firstLetter={homePageData?.card_title?.slice(0,1)}
          firstLetterClassName={"first-letter"}
          textClassName={"mainTitle"}
        />
        <CustomButton
          style={{ zIndex: 2 }}
          text={"Explore"}
          theme={"green"}
          onClick={() => {
            homePageData?.exlusive_button_link?.startsWith("/")
              ? navigate(homePageData?.exlusive_button_link)
              : (window.location.href = homePageData?.exlusive_button_link);
          }}
        />
        <div className="img-container">
          <img loading="lazy" src={homePageData?.card_image} />
        </div>
        <img loading="lazy" className="earthBg" src={AppImages.EarthBg} />
        <AboutSection />
      </div>
    </>
  );
}
