import "./footer.scss";
import React, { useEffect, useState } from "react";
import SectionWrapper from "../SectionWrapper";
import { AppImages } from "../../../Images/images";
import { Link, useLocation } from "react-router-dom";
import { getFooter } from "../../../services";
import TwitterLogo from "../../../Images/imageComponent/TwitterLogo";
import FacebookLogo from "../../../Images/imageComponent/FacebookLogo";
import InstagramLogo from "../../../Images/imageComponent/InstagramLogo";

export default function Footer() {
  const location = useLocation();
  const [footerData, setFooterData] = useState("");
  const getFooterData = async () => {
    const response = await getFooter();
    if (response.status === 200) {
      setFooterData(response.data.data[0]);
    }
  };

  useEffect(() => {
    !footerData && getFooterData();
  }, []);

  const handlePageLink = (path) => {
    if (location.pathname === path) {
      window.scrollTo(0, 0);
    }
  };
  return (
    <>
      <SectionWrapper
        background="linear-gradient(3.36deg, #000000 26.79%, #014EA9 117.21%)"
        borderRadius="50px 50px 0 0"
      >
        <div id="footer">
          <div className="links">
            <img loading="lazy" src={AppImages.Logo} className={"logo"} />
            <ul>
              <li>
                <Link to={"/about"} onClick={() => handlePageLink("/about")}>
                  About
                </Link>
              </li>
              <li>
                <Link
                  to={"/services"}
                  onClick={() => handlePageLink("/services")}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to={"/discover"}
                  onClick={() => handlePageLink("/discover")}
                >
                  Discover
                </Link>
              </li>
              <li>
                <Link
                  to={"/contactus"}
                  onClick={() => handlePageLink("/contactus")}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="descriptions">
            <p>{footerData?.footer_description}</p>
            <ul className="logos">
              <li>
                <Link to={footerData?.twitter_link}>
                  <TwitterLogo />
                </Link>
              </li>
              <li>
                <Link to={footerData?.facebook_link}>
                  <FacebookLogo />
                </Link>
              </li>
              <li>
                <Link to={footerData?.instagram_link}>
                  <InstagramLogo />
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-img">
            <img loading="lazy" src={AppImages.FooterAstronaut} />
          </div>
        </div>
      </SectionWrapper>
      <div className="bottom-footer">
        <p>© 2024 SpaceFrontier. All Rights Reserved.</p>
      </div>
    </>
  );
}
