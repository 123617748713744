import React from "react";
import "./discoversection.scss";
import SectionWrapper from "../common/SectionWrapper";
import { useSelector } from "react-redux";

export default function DiscoverSection() {
  const {discoverPageData} = useSelector((state)=> state.pageReducer)
  return (
    <>
      <SectionWrapper>
        <div id="discoversection">
          <div className="discoversection-title">
            <h1>{discoverPageData?.discover_maintitle}</h1>
          </div>
          {discoverPageData?.discover?.map((data, index) => {
            return (
              <div className="discover-container">
                <div className="discover-image">
                  <img loading="lazy" src={data.image} />
                </div>
                <div className="discover-description">
                  <h1>{data.title}</h1>
                  <p>{data.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </SectionWrapper>
    </>
  );
}
