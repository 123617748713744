import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";
import { useLocation } from "react-router-dom";
import Footer from "../../components/common/Footer";

export default function WebLayout({ children }) {
  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);
  return (
    <div>
      <Navbar />
      {children}
      <Footer />
    </div>
  );
}
