import React, { useEffect, useRef, useState } from "react";
import "./platinumcard.scss";
import SectionWrapper from "../common/SectionWrapper";
import CustomButton from "../common/CustomButton";
import { AppImages } from "../../Images/images";
import { getAllCreditCard } from "../../services";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cardApi } from "../../store/cardSlice";
import { useNavigate } from "react-router-dom";
import { pathNames } from "../../routes/WebRoutes";
import Pagination from "../common/Pagination";

export default function PlatinumCard({ cardsRef }) {
  const navigate = useNavigate();
  const cardData = useSelector((cardData) => cardData.cardSlice.cardDetail);
  const [paginatedData, setPaginatedData] = useState([]);

  const dispatch = useDispatch();
  const getCreditCardData = async () => {
    const params = {
      category: "",
      subCategory: "",
    };
    await dispatch(cardApi(params));
  };

  const handleLink = (cardData) => {
    const link = cardData.affiliateLink;
    const bannerData = {
      id: cardData._id,
      image: cardData.card_image,
      title: cardData.name,
      description: cardData.description,
    };
    link !== "NULL"
      ? link?.startsWith("/")
        ? navigate(link, { state: bannerData })
        : (window.location.href = link)
      : navigate(pathNames.OFFERPAGE, { state: bannerData });
  };

  useEffect(() => {
    getCreditCardData();
  }, []);

  return (
    <SectionWrapper>
      <div id="platinumcard">
        {paginatedData.length > 0 ? (
          paginatedData.map((item, index) => {
            return (
              <div className="platinumcard" key={index}>
                <div className="platinum-title">
                  <h3>{item.name}</h3>
                </div>
                <div className="platinum-content">
                  <div className="platinum-img">
                    <img loading="lazy" src={item.card_image} />
                  </div>
                  <div className="platinum-description">
                    <h3>{item.rewardsRate}</h3>
                    {item.description
                      .replace(/\\n/g, "\n")
                      .split("\n")
                      .map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                    {item.info2 && <p>{item.info2}</p>}
                    <div className="card-info-container">
                      <div className="card-info">
                        <p>Annual fee</p>
                        <h3>{item.annualFee}</h3>
                      </div>
                      <div className="card-info">
                        <p>Balance transfer intro APR</p>
                        <h3>{item.balanceTransferIntroAPR}</h3>
                      </div>
                      <div className="card-info">
                        <p>Regular APR</p>
                        <h3>{item.regularAPR}</h3>
                      </div>
                      <div className="card-info">
                        <p>Recommended credit</p>
                        <h3>{item.recommendedCredit}</h3>
                      </div>
                    </div>
                    <div className="apply-btn">
                      <CustomButton
                        text="Apply Now"
                        theme={"green"}
                        onClick={() => handleLink(item)}
                      ></CustomButton>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <h1 style={{ textAlign: "center" }}>No Credit Card Found</h1>
        )}
      </div>
      <Pagination
        allData={cardData}
        setPaginatedData={setPaginatedData}
        cardsRef={cardsRef}
      />
    </SectionWrapper>
  );
}
