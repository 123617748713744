import React, { useEffect } from "react";
import "./aboutSection.scss";
import CustomButton from "../common/CustomButton";
import SectionWrapper from "../common/SectionWrapper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { pathNames } from "../../routes/WebRoutes";
import { getPageData } from "../../services";
import { setPageListData } from "../../store/pageReducer";
export default function AboutSection() {
  const navigate = useNavigate();
  const { aboutUsPageData } = useSelector((state) => state.pageReducer);
  const dispatch = useDispatch();

  const getMyPageData = async () => {
    const response = await getPageData("about_us");
    if (response.status == 200) {
      dispatch(
        setPageListData({ name: "aboutUsPageData", data: response.data.data })
      );
    }
  };
  useEffect(() => {
    aboutUsPageData.length === 0 && getMyPageData();
  }, []);
  return (
    <div id="aboutSection">
      <SectionWrapper>
        <div className="aboutWrapper">
          <div className="imgBox">
            <img
              loading="lazy"
              className="aboutImg"
              src={aboutUsPageData?.aboutus_image}
            />
          </div>
          <div className="dataBox">
            <h1 className="title">About us</h1>
            <p className="detail">{aboutUsPageData?.aboutus_description}</p>
            <CustomButton
              text="Explore More"
              theme={"green"}
              onClick={() => navigate(pathNames.ABOUT)}
            />
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
}
