import React from "react";

export default function InstagramLogo() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5464_123172)">
        <path
          d="M14.9726 5.92676H9.00076C7.3058 5.92676 5.92723 7.30559 5.92723 9.00028V14.9721C5.92697 16.6674 7.3058 18.0459 9.00076 18.0459H14.9726C16.6676 18.0459 18.0464 16.6674 18.0464 14.9724V9.00055C18.0464 7.30559 16.6676 5.92676 14.9726 5.92676ZM11.9863 15.8963C9.83058 15.8963 8.07714 14.1426 8.07714 11.9871C8.07714 9.83143 9.83085 8.07799 11.9863 8.07799C14.1417 8.07799 15.8954 9.83169 15.8954 11.9871C15.8954 14.1426 14.1417 15.8963 11.9863 15.8963ZM15.7658 8.73395C15.3296 8.73395 14.9758 8.38015 14.9758 7.9439C14.9758 7.50765 15.3296 7.15385 15.7658 7.15385C16.2021 7.15385 16.5559 7.50765 16.5559 7.9439C16.5559 8.38015 16.2023 8.73395 15.7658 8.73395Z"
          fill="currentColor"
        />
        <path
          d="M11.9863 14.8417C13.5633 14.8417 14.8417 13.5633 14.8417 11.9863C14.8417 10.4093 13.5633 9.13086 11.9863 9.13086C10.4093 9.13086 9.13089 10.4093 9.13089 11.9863C9.13089 13.5633 10.4093 14.8417 11.9863 14.8417Z"
          fill="currentColor"
        />
        <path
          d="M11.9866 0.131836C5.43934 0.131836 0.131866 5.43931 0.131866 11.9865C0.131866 18.5337 5.43934 23.8412 11.9866 23.8412C18.5338 23.8412 23.8412 18.5337 23.8412 11.9865C23.8412 5.43931 18.5338 0.131836 11.9866 0.131836ZM19.1002 14.9721C19.1002 17.2479 17.2487 19.0993 14.9726 19.0993H9.00075C6.72492 19.0993 4.87348 17.2479 4.87348 14.9721V9.0002C4.87348 6.72436 6.72492 4.87292 9.00075 4.87292H14.9726C17.2487 4.87292 19.1002 6.72436 19.1002 9.0002V14.9721Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5464_123172">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
