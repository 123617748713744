import React from 'react'
import { Route, Routes } from 'react-router-dom';
import { WebRoutes } from './WebRoutes';
import NotFound from '../pages/NotFound';

export default function AppRoutes() {
  return (
    <Routes>
      {WebRoutes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            element={
                <route.layout>
                  <route.component />
                </route.layout>
            }
          />
        );
      })}
      <Route path='*' element={<NotFound/>}/>
    </Routes>
  )
}
