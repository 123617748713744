import React, { useState } from "react";
import "./offersform.scss";
import SectionWrapper from "../common/SectionWrapper";
import OfferInput from "../common/OfferInput";
import CustomButton from "../common/CustomButton";
import { isEmailValid, isEmpty } from "../../utils/validation";
import { SendForm } from "../../services";
import toast from "react-hot-toast";

export default function OffersForm({ cardId = "" }) {
  const names = {
    firstName: "firstname",
    middleName: "middlename",
    lastName: "lastname",
    email: "email",
    address: "address",
    aptUnit: "aptunit",
    city: "city",
    state: "state",
    zipCode: "zipcode",
    socialSecNumber: "socialsecuritynumber",
    dobMonth: "dobmonth",
    dobDay: "dobday",
    dobYear: "dobyear",
  };

  const initialData = {
    firstname: "",
    middlename: "",
    lastname: "",
    email: "",
    address: "",
    aptunit: "",
    city: "",
    state: "",
    zipcode: "",
    socialsecuritynumber: "",
    dobmonth: "",
    dobday: "",
    dobyear: "",
  };

  const [formData, setFormData] = useState(initialData);
  const [errors, setErrors] = useState(initialData);

  const validate = (name, value) => {
    if (value.length > 0) {
      if (name === names.email) {
        setErrors({ ...errors, [name]: isEmailValid(value) });
        return;
      }
    }
    setErrors({ ...errors, [name]: isEmpty(value) });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value.startsWith(" ")
      ? event.target.value.replace(" ", "")
      : event.target.value;
    if (
      name === names.zipCode ||
      name === names.dobDay ||
      name === names.dobMonth ||
      name === names.dobYear
    ) {
      value = event.target.value.replace(/\D/g, "");
    }
    let preLength = 0;
    if (name === names.socialSecNumber) {
      value = value.replace(/[^0-9-]/g, "");
      if (
        (preLength < value.length && value.length === 4) ||
        value.length === 7
      ) {
        value = value.slice(0, -1);
        preLength--;
      } else {
        value = value.length === 3 || value.length === 6 ? value + "-" : value;
        preLength++;
      }
    }
    const maxLength = event.target.maxLength;
    const maxValue = event.target.max;
    validate(name, value);
    if (value.length > maxLength && maxLength !== -1) {
      setFormData({ ...formData, [name]: value.slice(0, maxLength) });
      return;
    }
    if (name === names.socialSecNumber) {
      if (value.length < event.target.minLength + 2) {
        setFormData({ ...formData, [name]: value });
        setErrors({
          ...errors,
          [name]: `Please enter minimum ${event.target.minLength} characters`,
        });
        return;
      }
    } else {
      if (value.length < event.target.minLength) {
        setFormData({ ...formData, [name]: value });
        setErrors({
          ...errors,
          [name]: `Please enter minimum ${event.target.minLength} characters`,
        });
        return;
      }
    }
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.values(errors).every((val) => val === "")) {
      const errorsData = {};
      for (const item in formData) {
        errorsData[item] = isEmpty(formData[item]);
      }
      setErrors(errorsData);
      if (Object.values(errorsData).every((val) => val === "")) {
        const dob =
          formData.dobmonth + "/" + formData.dobday + "/" + formData.dobyear;
        const data = {
          first_name: formData.firstname,
          middle_name: formData.middlename,
          last_name: formData.lastname,
          email: formData.email,
          address: formData.address,
          apt_unit: formData.aptunit,
          city: formData.city,
          state: formData.state,
          zipcode: formData.zipcode,
          SSN_No: formData.socialsecuritynumber,
          dob: dob,
        };
        cardId ? (data.creditcardId = cardId) : (data.is_banner = true);
        const response = await SendForm(data);
        if (response.status === 201) {
          setFormData(initialData);
          setErrors(initialData);
          toast.success("Thank you for applying");
        } else {
          console.log("Error........!");
        }
      }
    } else {
      return;
    }
  };
  return (
    <SectionWrapper>
      <form id="offerform" onSubmit={handleSubmit}>
        <div className="offerform-container">
          <h2>Please provide some basic information</h2>
          <div className="offerform-input-container ">
            <div className="offerform-input">
              <OfferInput
                type="text"
                placeholder="First Name"
                maxChar={40}
                name={names.firstName}
                value={formData.firstname}
                label="First Name"
                error={errors.firstname}
                handleChange={handleChange}
              />
              <OfferInput
                type="text"
                placeholder="Middle Initial"
                maxChar={40}
                name={names.middleName}
                value={formData.middlename}
                label="Middle Initial"
                error={errors.middlename}
                handleChange={handleChange}
              />
            </div>
            <div className="offerform-input">
              <OfferInput
                type="text"
                placeholder="Last Name"
                maxChar={40}
                name={names.lastName}
                value={formData.lastname}
                label="Last Name"
                error={errors.lastname}
                handleChange={handleChange}
              />
              <OfferInput
                type="text"
                placeholder="you@company.com"
                maxChar={320}
                name={names.email}
                value={formData.email}
                label="Email Address"
                error={errors.email}
                handleChange={handleChange}
              />
            </div>
            <div className="offerform-input">
              <OfferInput
                type="text"
                placeholder="Your Address"
                name={names.address}
                value={formData.address}
                label="Address"
                error={errors.address}
                handleChange={handleChange}
              />
              <OfferInput
                type="text"
                placeholder="Apt/Unit"
                name={names.aptUnit}
                value={formData.aptunit}
                label="Apt/Unit"
                error={errors.aptunit}
                handleChange={handleChange}
              />
            </div>
            <div className="offerform-input">
              <OfferInput
                type="text"
                placeholder="City"
                maxChar={40}
                name={names.city}
                value={formData.city}
                label="City"
                error={errors.city}
                handleChange={handleChange}
              />
              <OfferInput
                type="text"
                placeholder="State"
                maxChar={40}
                name={names.state}
                value={formData.state}
                label="State"
                error={errors.state}
                handleChange={handleChange}
              />
            </div>
            <div className="offerform-input">
              <OfferInput
                type="number"
                placeholder="XXXXXX"
                maxChar={6}
                minChar={6}
                name={names.zipCode}
                value={formData.zipcode}
                label="ZIP Code (XXXXXX)"
                error={errors.zipcode}
                handleChange={handleChange}
              />
              <OfferInput
                type="text"
                placeholder="XXX-XX-XXXX"
                maxChar={11}
                minChar={9}
                name={names.socialSecNumber}
                value={formData.socialsecuritynumber}
                label="Social Security Number (XXX-XX-XXXX)"
                error={errors.socialsecuritynumber}
                handleChange={handleChange}
              />
            </div>
            <div className="offerform-input">
              <OfferInput
                type="number"
                maxChar={2}
                minChar={1}
                maxValue={12}
                placeholder="MM"
                name={names.dobMonth}
                value={formData.dobmonth}
                label="Date of Birth (MM/DD/YYYY)"
                error={errors.dobmonth}
                handleChange={handleChange}
              />
              <OfferInput
                type="number"
                maxChar={2}
                minChar={1}
                maxValue={31}
                placeholder="DD"
                name={names.dobDay}
                value={formData.dobday}
                label="Dummy"
                error={errors.dobday}
                handleChange={handleChange}
              />
              <OfferInput
                type="number"
                maxChar={4}
                minChar={4}
                maxValue={new Date().getFullYear()}
                placeholder="YYYY"
                name={names.dobYear}
                value={formData.dobyear}
                label="Dummy"
                error={errors.dobyear}
                handleChange={handleChange}
              />
            </div>
            <CustomButton
              text={"Submit"}
              type={"submit"}
              theme={"green"}
              className="form-btn"
            />
          </div>
        </div>
      </form>
    </SectionWrapper>
  );
}
