import React, { useEffect, useState } from "react";
import "./welcomeModel.scss";
import { AppImages } from "../../Images/images";
import { useDispatch, useSelector } from "react-redux";
import { setPageListData } from "../../store/pageReducer";
import { getWelcomeModel } from "../../services";

export default function WelcomeModel() {
  const { modelOpened } = useSelector((state) => state.pageReducer);
  const [modelData, setModelData] = useState("");
  const dispatch = useDispatch();
  const closeModel = () => {
    dispatch(setPageListData({ name: "modelOpened", data: true }));
  };

  const getModelData = async () => {
    const response = await getWelcomeModel();
    if (response.status === 200) {
      setModelData(response.data.data);
    } else {
      console.log(response);
    }
  };

  useEffect(() => {
    getModelData();
  }, []);

  useEffect(() => {
    if (modelData) {
      if (modelOpened) {
        document.body.style.overflow = "scroll";
      } else {
        document.body.style.overflow = "hidden";
      }
    }
  }, [modelData, modelOpened]);

  return (
    <>
      {modelData && (
        <div
          id="welcomeModel"
          className={!modelOpened ? "openModel" : "closeModel"}
        >
          <div className="model">
            <div className="imgBox">
              <img
                src={AppImages.CloseCross}
                className="close-model"
                onClick={closeModel}
              ></img>
              <img src={modelData?.popup_image} className="model-img"></img>
            </div>
            <div className="dataBox">
              <div className="title">{modelData?.popup_title}</div>
              <p className="detail">{modelData?.popup_description}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
