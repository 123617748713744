import React, { useEffect } from "react";
import "./tipsandcardcompare.scss";
import ChooseCreditcard from "../../components/ChooseCreditcard";
import FormBanner from "../../components/common/FormBanner";
import Subscribe from "../../components/common/Subscribe";
import FAQSection from "../../components/FAQSection";
import { subscribecard } from "../../components/helpers/subscribeData";
import { useDispatch, useSelector } from "react-redux";
import { getCardCompare } from "../../services";
import { setPageListData } from "../../store/pageReducer";
import VideoPlayer from "../../components/VideoPlayer";

export default function TipsandCardcompare() {
  const { cardComparePageData } = useSelector((state) => state.pageReducer);
  const dispatch = useDispatch();
  const getPageData = async () => {
    const response = await getCardCompare();
    if (response.status === 200) {
      dispatch(
        setPageListData({
          name: "cardComparePageData",
          data: response.data.data,
        })
      );
    } else {
      console.log("Error : ", response);
    }
  };

  useEffect(() => {
    cardComparePageData.length === 0 && getPageData();
  }, []);

  return (
    <div id="tipsandcardcompare">
      <ChooseCreditcard />
      <div className="cardcredit-info">
        {cardComparePageData?.right_card?.map((item) => {
          return (
            <FormBanner
              img={item.image}
              title2={item.title}
              description={item.description}
            />
          );
        })}
      </div>
      <VideoPlayer source={cardComparePageData?.tips_link}/>
      <FAQSection />
      <Subscribe
        heading={subscribecard.heading}
        subscriptionType={"news_letter"}
      />
    </div>
  );
}
