import React from 'react'
import './notfound.scss'
import NotFoundSvg from '../../Images/imageComponent/NotFoundSvg'
import CustomHeading from '../../components/common/CustomHeading'
import { Link } from 'react-router-dom'

export default function NotFound() {
  return (
    <div id='NotFound'>
        <NotFoundSvg/>
        <div className='box'>
        <CustomHeading text={"OOPS!"}/>
        <h3 className='sub-title'>Page Not Found!</h3>
        <Link to={"/"} className='link'>Home</Link>
        </div>
    </div>
  )
}
