import WebLayout from "../layout/WebLayout";
import ApartmentPage from "../pages/ApartmentPage";
import CarLoan from "../pages/CarLoan";
// import CreditCard from "../pages/Services";
import Home from "../pages/Home";
import OffersPage from "../pages/OffersPage";
import PersonalizedForm from "../pages/PersonalizedForm";
import TipsandCardcompare from "../pages/TipsandCardcompare";
import Services from "../pages/Service";
import About from "../pages/About";
import Discover from "../pages/Discover";
import ContactUs from "../pages/ContactUs";

export const pathNames = {
  DEFAULT: "/",
  SERVICES: "/services",
  OFFERPAGE: "/offerpage",
  COMPARECARDS: "/cardcompare",
  CARLOAN: "/carloan",
  APARTMENT: "/apartment",
  ABOUT: "/about",
  DISCOVER: "/discover",
  CONTACTUS: "/contactus",
};

export const WebRoutes = [
  { path: pathNames.DEFAULT, layout: WebLayout, component: Home },
  { path: pathNames.SERVICES, layout: WebLayout, component: Services },
  { path: pathNames.OFFERPAGE, layout: WebLayout, component: OffersPage },
  { path: pathNames.ABOUT, layout: WebLayout, component: About },
  { path: pathNames.DISCOVER, layout: WebLayout, component: Discover },
  { path: pathNames.CONTACTUS, layout: WebLayout, component: ContactUs },
  {
    path: pathNames.COMPARECARDS,
    layout: WebLayout,
    component: TipsandCardcompare,
  },
  { path: pathNames.CARLOAN, layout: WebLayout, component: CarLoan },
  { path: pathNames.APARTMENT, layout: WebLayout, component: ApartmentPage },
  // { path: "/personalizedform", layout: WebLayout, component: PersonalizedForm },
];
