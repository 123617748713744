import React, { useEffect, useState } from "react";
import "./creditanalysis.scss";
import CustomHeading from "../common/CustomHeading";
import DiscoverCardSwiper from '../DiscoverCardSwiper'
import { AppImages } from "../../Images/images";
import { getCreditCardBenefit } from "../../services";

export default function CreditAnalysis() {
  const [benefitData, setBenefitData] = useState([])
  const getBenefitsData = async ()=>{
    const response = await getCreditCardBenefit()
    if(response.status === 200){
      const data = response.data.data.map((item)=>{
        return {
          title: item.banefit_title,
          description: item.banefit_description
        }
      })
      setBenefitData(data)
    }
  }  
  useEffect(()=>{
    getBenefitsData()
  },[])
  // const images = [
  //   AppImages.CreditAnalysis,
  //   AppImages.CreditAnalysis,
  //   AppImages.CreditAnalysis,
  // ];
 
  return (
    <div id="creditanalysis">
      <CustomHeading text={"Benefits of Credit Analysis "} />
      <div className="cards-box">
        <DiscoverCardSwiper data={benefitData} buttonVisible={false}/>
      </div>
    </div>
  );
}
