import React from "react";
import "./contactus.scss";
import ContactSection from "../../components/ContactSection";

export default function ContactUs() {
  return (
    <div>
      <ContactSection />
    </div>
  );
}
